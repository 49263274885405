/* .outerWorkFlow {
  background-color: #e9e7e2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.card {
  display: flex;
  border: 2px solid black;
  box-shadow: 2px solid black;
  margin: 0px 5%;
}

.cardimage {
  background-color: #c42814;
  width: 50%;
  overflow: hidden;
  position: relative;
}
.cardimage::before {
  background-color: #c42814;
  content: "";
  position: absolute;
  left: -6.1em;
  top: 50%;
  transform: translateY(-50%) rotate(20deg);
  width: 40%;
  height: 140%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.skewedLeft .cardimage::before {
  transform: translateY(-50%) rotate(-20deg);
  left: initial;
  right: 6.1em;
}

.cardText h6 {
  color: black;
  text-transform: capitalize;
} */
.workflowbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  gap: 10px;
}
.workflowbody h2 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
  margin-top: 30px;
  color: #c42814;
}
/* .image img {
  height: 200px;
  width: 200px;
  object-fit: cover;
} */
.skewed {
  width: 80%;
  color: #f1f1f1;
  display: flex;
  background: #c42814;
  margin: 2em 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.text {
  padding: 1.5em;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image {
  width: 70%;
  height: 15em;
  background-color: white;
  color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

/* 
.shapedividers_com-6636{
  overflow:hidden;
  position:relative;
  }
  .shapedividers_com-6636::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 90px 100%;
  background-position: 0% 50%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.17 35.28" preserveAspectRatio="none"><path d="M1 35.28c.8-3.17-.38-7.3-.55-10.04-.17-2.76 1.47-9.25 1.3-12.68C1.58 9.14.91 7.92.88 5.52.86 3.12 1.76 0 1.76 0H0v35.28z" fill="%23fbd8c2"/></svg>'); 
  } */

.image::before {
  /* background-color: #c42814; */
  content: "";
  position: absolute;
  left: -6.1em;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  width: 20%;
  height: 140%;
  background: #c42814;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.skewedLeft .image::before {
  transform: translateY(-50%) rotate(-15deg);
  left: initial;
  right: -6.1em;
}
.learnText {
  padding-left: 20%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
}
.buildtext {
  padding-right: 20%;
  padding-left: 10%;
}
.image p {
  text-align: justify;
}
.image h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1em;
  letter-spacing: 0.25rem;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 921px) {
  .skewed {
    display: block;
    width: 20em;
  }
  .image,
  .text {
    width: 100%;
  }
  .image h5 {
    font-size: 20px;
  }
  .Workp {
    padding-bottom: 50px;
    font-size: 15px;
  }
  .learnText {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 30%;
  }
  .buildtext {
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 10%;
  }
  .skewed .image::before {
    width: 110%;
    height: 45%;
    top: -5em;
    left: 50%;
    transform: translateX(-50%) rotate(10deg);
  }
  .skewedLeft .image::before {
    transform: translateX(-50%) rotate(-10deg);
    top: initial;
    bottom: -5em;
  }
}
@media screen and (max-width: 750px) {
  .skewed {
    display: block;
    width: 20em;
  }
  .image,
  .text {
    width: 100%;
  }
  .image h5 {
    font-size: 20px;
  }
  .Workp {
    padding-bottom: 50px;
    font-size: 15px;
  }
  .learnText {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 30%;
  }
  .buildtext {
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 10%;
  }
  .skewed .image::before {
    width: 110%;
    height: 45%;
    top: -5em;
    left: 50%;
    transform: translateX(-50%) rotate(10deg);
  }
  .skewedLeft .image::before {
    transform: translateX(-50%) rotate(-10deg);
    top: initial;
    bottom: -5em;
  }
}
