.swiperOuter {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 45px;
}
/* .swiperOuter img {
  box-shadow: 2px black;
} */
#bottomimage {
  box-shadow: 2px black;
}
.swiperimage {
  width: 60%;
}
.description {
  width: 30%;
}
.EventsDescription h2 {
  font-size: 40px;
}
.EventsDescription p {
  /* font-size: 42px; */
  text-align: justify;
  padding-bottom: 10px;
}
.EventsDescription button {
  margin-top: 20px;
  background-color: var(--eblueshade1);
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: var(--ecream);
  border: none;
}
.EventsDescription button:hover {
  background-color: var(--emaroon);
}
.events-swiper {
  display: flex;
}
#swipperouter {
  height: fit-content;
}
#swiperSlide {
  height: 100%;
}

@media (max-width: 990px) {
  #swiperSlide {
    height: 26rem;
  }
}
@media screen and (max-width: 480px) {
  #swiperSlide {
    height: 680px;
  }
  .swiperOuter {
    flex-direction: column;
  }
}
