:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --ered: #c42814;
  --eblack: #0b0b11;
  --eblueshade0: #08b5e0;
  --eblueshade1: #065b9c;
  --eblueshade2: #055079;
  --eblueshade3: #04385a;
  --eblueshade4: #042c42;
  --emaroon: #5a1609;
  --ecream: #e9e7e2;
}
* {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0px;
  padding: 0px;

  overflow-x: hidden;
}
/* Navbar */
.navbar {
  color: white;
  height: 80px;
}
ul {
  font-family: inherit !important;
}
.nav-link {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.2px;
}
.nav-item {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.2px;
}
.navbar img {
  object-fit: contain;
  height: 50px;
  margin-left: 12px;
}
.nav-drop {
  background-color: black;
  color: white;
}
/* .active {
  text-decoration-skip-ink: none;
  padding-bottom: 5px;
  text-decoration: underline;
  text-underline-offset: 7px;
} */
/* .navbar-nav .nav-link.active {
  color: white;
} */
.dropdown-item.active {
  background-color: white;
  color: black;
}
.nav-element {
  color: white;
  border-radius: 10px;
  width: fit-content;
  padding: 7px 5px;
  /* font-weight: 500; */
  /* background-color: black; */
  /* padding: 0px 5px; */
}
.nav-element:hover {
  background-color: white;
  color: black;
  width: fit-content;
  border-radius: 10px;
  /* font-weight: 500; */
  /* padding: 0px 5px; */
}

.active {
  background-color: white;
  color: black;
  font-weight: 500;
  border-radius: 10px;
  text-decoration: none;
  /* padding: 0px 5px; */
}
.header-light {
  background-color: rgba(0, 0, 0, 0.1);
}
.header-bg {
  background-color: rgba(0, 0, 0, 1);
}
/* Hero section  */

/* .Hero-section {
  height: 100vh;
} */
.Hero-section {
  background-image: url(./img/image.jpg);
  filter: brightness(50%);
  height: 600px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Hero-image {
  width: 100%;
  height: auto;
}
.Hero-section:hover {
  filter: brightness(100%);
}

/* about section */
.about-section {
  padding: 6em 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutrow {
  width: 85%;
}
.text-col {
  padding: 30px;
  position: relative;
}

.image-col {
  text-align: center;
}
.image-col img {
  width: 100%;
  height: 500px;
  object-fit: contain;
}
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-right {
  animation: slideRight 3s ease-in-out, fadeIn 2s ease-in-out;
}

.slide-left {
  animation: slideLeft 3s ease-in-out, fadeIn 2s ease-in-out;
}
/*  */
.hiddenLeft {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 3s;
}
.hiddenRight {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100%);
  transition: all 3s;
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}
/*  */
/* .fade-in {
  animation: fadeIn 5s ease-in-out;
} */
h3 {
  font-size: 17px;
  font-family: "Courier New", Courier, monospace;
  color: var(grey);
}

/* h4 {
  font-size: 30px;
  line-height: 55px;
  font-weight: 590;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  color: var(--eblueshade1);
  /* padding-right: 100px; */
/* font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; */

h4 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: 2px;
  color: var(--eblueshade1);
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  text-align: center;
  /* text-decoration-line: underline; */
  text-underline-offset: 10px;
}
/* h1 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: 2px;
  color: var(--eblueshade1);
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-decoration-line: underline;
  text-underline-offset: 10px;
} */
h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 1.3px;
  color: var(--eblueshade1);
  text-transform: uppercase;
}
h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  color: var(--eblack);
}
.text-section p {
  font-family: "Barlow", sans-serif;
  color: #444;
  font-weight: 400;
  margin-top: 10px;
  text-align: justify;
  line-height: 28px;
}

.text-button {
  background-color: var(--eblueshade1);
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: var(--ecream);
}

.text-button:hover {
  /* background-color: var(--emaroon); */
  /* color: var(--ecream); */
  color: var(--eblueshade1);
  background-color: white;
  border: 2px solid var(--eblueshade1);
}

.view-button {
  background-color: var(--eblueshade1);
  border-radius: 10px;
  padding: 7px 20px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: var(--ecream);
}

.view-button:hover {
  background-color: white;
  color: var(--eblueshade1);
  border: 2px solid var(--eblueshade1);
}
/* event section */
.event-section {
  margin-top: 40px;
}
.event-section h4 {
  margin-bottom: 0px;
}
/* swiper */
.event-section img {
  height: auto;
  width: 80%;
  object-fit: contain;
  align-items: center;
}

.outer-swiper button {
  margin-top: 20px;
  background-color: var(--eblueshade1);
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: var(--ecream);
  border: none;
}
.outer-swiper button:hover {
  /* background-color: var(--emaroon); */
  background-color: white;
  color: var(--eblueshade1);
  border: 2px solid var(--eblueshade1);
}
/* .outer-swiper img {
  border: 12px solid black;
  box-shadow: 5px 10px 8px #888888;
} */
.EventsDescription h2 {
  font-size: 35px;
}
.EventsDescription p {
  /* font-size: 42px; */
  text-align: justify;
  padding-bottom: 10px;
}
.carousel .slide {
  text-align: left;
}

/* Services */
.services {
  margin-bottom: 2rem;
  position: relative;
  /* background-image: url(../public/images/watermark3.png); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-dodge; */
}
.services h1 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: 2px;
  color: var(--eblueshade1);
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  text-align: center;
  /* text-decoration-line: underline; */
  text-underline-offset: 10px;
}
.services .container {
  margin-top: 5em;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}
.services .backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.221);
  top: 0;
  left: 0;
  overflow: hidden;
}

/* .services .backdrop img {
  transform: rotate(45deg);
} */
.services .container h1 {
  position: absolute;
  z-index: 5;
  top: 5%;
  left: 40%;
}
.services .card {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.119), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  position: relative;
  height: 20rem;
  /* background-image: url(../public/images/serviceBG.jpg); */
}
.services .services-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
}
.card-body {
  z-index: 5;
}
.services .row {
  margin-top: 10px;
}
/* .service .card::before {
  background-image: url(../public/images/serviceBG.jpg);
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
} */

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* adjust the height as needed */
  width: 100px; /* adjust the width as needed */
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #e9e9e9; /* adjust the background color as needed */
  margin: 0 auto; /* centers the circle div horizontally */
}

.circle:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-subtitle {
  font-size: 20px;
  letter-spacing: 0.75px;
}

.card-text {
  margin-top: 10px;
  font-weight: 400;
}

/* hire */
/* .hire {
  background-color: #13356f;
} */
.hirerow {
  margin-left: 4rem;
}
.hire h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  padding-left: 45px;
  letter-spacing: 1.2px;
}
.hire h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 13px;
  letter-spacing: 1.3px;
  padding-left: 45px;
  text-transform: uppercase;
  color: #00a0eb;
}
.hiregrid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  padding: 5px;
}

.hire img {
  border-radius: 5px;
}

/* leaders */

.leaders {
  background-image: url("./img/background1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  padding: 30px 40px 40px 40px;
  margin: 20px 0px;
}
/* .leader .card {
  box-shadow: 10px 10px 20px black;
}
.leader img {
  height: 200px;
}
.leader h4 {
  font-size: 26px;
}
.intro {
  text-align: center;
  align-content: center;
  margin-top: 10px;
} */
/* Clients */

.clients {
  padding: 5px;
  margin-top: 50px;
  align-content: center;
  text-align: center;
}

.clients h1 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: 2px;
  color: var(--eblueshade1);
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  text-align: center;
  /* text-decoration-line: underline; */
  text-underline-offset: 10px;
}

.clients img {
  object-fit: contain;
}

#container {
  width: 90%;
  /* overflow: hidden; */
  margin: 10px auto;
}

.outer-clients {
  display: flex;
}

/*photobanner*/
/* .photobanner {
  height: 233px;
  margin-bottom: 80px;
} */
.photobanner img {
  display: inline-block;
  height: 120px;
  width: 120px;
  margin: 50px 35px;
}
/* footer */
.Footer {
  background-color: var(--eblack);
  padding-top: 50px;
  color: white;
}

.Footer p {
  color: var(--ecream);
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-top: 18px;
}
.Footer img {
  /* display: block; */
  height: 100px;
  width: 230px;
  /* margin-left: 35px; */
}
.Footer sub {
  padding-left: 50px;
}
.Footer hr {
  color: white;
}
/* .location {
  letter-spacing: 1.4px;
  line-height: 28px;
  float: right;
} */
.subscribe {
  margin-top: 40px;
  font-size: 23px;
  color: #fff;
  letter-spacing: 1.3px;
  font-weight: 600;
  text-transform: none;
  line-height: 25px;
}
#location1 {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  /* text-align: center; */
  letter-spacing: 1.4px;
  line-height: 28px;
  /* float: left; */
  /* Adjust the height as needed */
}
.Footer i {
  margin-right: 10px;
  color: white;
}

i:hover {
  /* color: var(--ered); */
  color: var(--eblueshade1);
}
.Footer a {
  margin-right: 7px;
}
.Footer .copyright {
  padding: 20px;
  text-align: center;
  padding-bottom: 20px;
}
#footer-company-image {
  height: 40px;
  object-fit: cover;
  width: 120px;
  display: inline;
  float: right;
}
/* Cards */
.cardleaders {
  font-family: Helvetica, sans-serif;
  color: rgb(211, 211, 211);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: center;
}
.cardleaders p {
  color: white;
  margin: 5px 10px;
  /* letter-spacing: 1px; */
  line-height: 21px;
}
.card-container {
  width: 250px;
  height: 370px;
  perspective: 800px;
  margin-left: 15px;
}
.cards {
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 1500ms;
  transform-style: preserve-3d;
}

.card-container:hover > .cards {
  cursor: pointer;
  transform: rotateY(180deg);
}
.front {
  background-color: rgb(0, 0, 0, 0.9);
}
.front h2 {
  text-align: center;
  color: white;
  padding: 0px;
  font-family: "Barlow", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0px;
  font-size: 18px;
}
.front h3 {
  color: white;
  padding: 0px;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
/* .back {
  background-image: url("./img/Cardimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
} */

.front,
.back {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 2px 2px 5px 2px rgba(50, 50, 50, 0.25);
  position: absolute;
  backface-visibility: hidden;
}
.cardleaders img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.back {
  /* background-color: #3a3a3a; */
  background-color: black;
  transform: rotateY(180deg);
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
/* Landing */
.landing {
  height: 600px;
  background-color: #0b0b11;
  display: flex;
  justify-content: center;
}

#inquire {
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  /* font-family:  */
  font-weight: bolder;
  letter-spacing: 1px;
  padding: 17px 60px;
  background-color: #e9e7e2;
  border-radius: 10px;
  color: #055079;
}

#inquire:hover {
  background-color: #2ac59f;
  color: white;
}

.landing img {
  /* display: block; */
  max-width: 100%;
  height: 90vh;
  object-fit: cover;
  overflow: hidden;
}

.heart {
  font-size: 150px;
  animation: beat 1s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.2);
  }
}

/* font-family: 'Merriweather', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Ubuntu', sans-serif; */

/* .btn-circle.btn-lg {
  background-color: white;
  width: 40px;
  height: 40px;
  padding: 5px 11px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
} */

/* contact us */
.contacts {
  margin-top: 100px;
  margin-bottom: 40px;
}

.contactText {
  background-color: var(--ecream);
  /* background-image: url("../public/images/contact.jpg"); */
  margin-left: 10px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  font-weight: bold;
  margin-right: 30px;
}

.contactBanner {
  /* background-image: url("./img/contactUS1.jpg"); */
  /* background-size: cover; */
  position: relative;
  height: 300px;
  color: white;
  margin-top: 80px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUs-Backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  top: 0;
  left: 0;
  overflow: hidden;
}
.contactBanner img {
  object-fit: cover;
  width: 100%;
  height: inherit;
  object-position: 100% 49%;
  width: 100%;
  overflow: hidden;
}
.contactBanner h1 {
  margin: 0px;
  font-size: 33px;
  font-weight: 700;
  letter-spacing: 0.3rem;
  line-height: 3rem;
  z-index: 5;
  color: black;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  /* font-family: "Montserrat", sans-serif; */
}
.contactText p {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0.3rem;
  line-height: 3rem;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
}
.contactForm {
  background-color: var(--ecream);
}

.contactForm p {
  margin-top: 3rem;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1.2px;
  line-height: 45px;
  text-transform: inherit;
  padding: 0px 45px;
}

.locationtxt {
  background-image: url("../public/images/contact.jpg");
  margin-left: 10px;
  /* text-transform: capitalize; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-weight: bold;
  margin-right: 30px;
}
#title {
  font-size: 30px;
}
.locationtxt p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.2px;
  /* line-height: px; */
  /* text-transform: inherit; */
  padding: 0px 45px;
}
/* .maps {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */
/* iframe{

} */
.form {
  margin: 0px;
  padding: 0px;
}
.Contactgrid {
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.Contact input {
  height: 61px;
  padding: 20px;
}

.Contactgrid-item {
  padding: 10px;
}

.span-2 {
  grid-column: span 2;
}

.contacts label {
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 0.25px;
}

.contacts form {
  margin: 5px 15px;
}

.contacts form textarea::placeholder,
.contacts input::placeholder {
  color: #00000085;
  font-size: 14px;
  margin-left: 0px;
}

.contacts form textarea,
.contacts form input {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #000000eb;
  border-radius: 0px;
}

.contacts form textarea:focus,
.contacts form input:focus,
.contacts form textarea:hover,
.contacts form input:hover {
  background-color: transparent;
  outline: none;
  border: 1px solid #00000085;
  border-radius: 0px;
  box-shadow: none;
}
.gmail {
  text-transform: none;
}
#gmail {
  /* text-transform: none; */
  text-decoration: none;
  color: white;
}
/* carosel */

.eventcarousel {
  margin-top: 80px;
  height: 560px;
  background-image: url("./image.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparent {
  height: 70vh;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.transparent img {
  height: 350px;
  width: 100%;
  object-fit: contain;
}

.transparent a {
  padding: 10px 20px;
  background-color: var(--eblueshade1);
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.15em;
  border-radius: 10px;
}
.transparent a:hover {
  background-color: var(--ecream);
  color: var(--eblueshade4);
}
.contactbtn {
  width: 100%;
  background-color: #000000eb;
  color: #e9e7e2;
  padding: 15px 20px;
  border-radius: 0px;
  border: 2px solid #000000eb;
  text-transform: capitalize;
}
.contactbtn:hover {
  width: 100%;
  background-color: #e9e7e2;
  color: #000000eb;
  padding: 15px 20px;
  border-radius: 0px;
  border: 2px solid #000000eb;
}
/* Rentals */
.rentalrow {
  height: 700px;
}
.parent {
  position: relative;
  /* margin: 40px; */
}
#UpperDiv {
  position: absolute;
  /* background-color: red; */
  width: 300px;
  height: 400px;
  /* z-index: 9; */
}
#LowerDiv {
  position: absolute;
  /* background-color: blue; */
  width: 300px;
  height: 400px;
  top: 100px;
  left: 100px;
}
.parent img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.front img {
  object-position: top;
}

.card-container:last-child .front img {
  object-position: center;
}
/*Test  */
.about-outer-section {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.about-text-section {
  padding-left: 50px;
  padding-right: 10px;
  position: relative;
}
.about-image-section {
  width: 50%;
  height: 700px;
  position: relative;
  z-index: 2;
}
.outer-about-text-section {
  background-color: var(--ecream);
  width: 35%;
  padding: 40px 30px;
  z-index: 1;
}
.about-image-section img {
  margin-top: 50px;
  object-fit: cover;
  width: 100%;
  height: 70%;
}
.about-image-small {
  position: absolute;
  height: 360px;
  width: auto;
  bottom: 20px;
  /* left: 300px; */
  right: -60px;
  z-index: 5;
}

/*Final Carosel*/
.RedColorH4 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
  margin-top: 30px;
  text-transform: none;
  font-size: 40px;
  color: #c42814;
}

.maps iframe {
  width: 100%;
}

@media (max-width: 1030px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 915px) {
  .contactBanner {
    margin-right: 0px;
    padding: 0;
  }
  .locationtxt {
    display: none;
  }
  .contactForm {
    width: 100%;
  }
  .contactText {
    display: none;
  }
  .maps iframe {
    width: 100%;
  }
  .card-subtitle {
    font-size: 18px;
    letter-spacing: 0.75px;
  }

  .card-text {
    font-size: 14px;
  }
  .EventsDescription h6 {
    padding-top: 10px;
    margin-left: 10px;
    /* text-align: center; */
  }
  .EventsDescription h2 {
    font-size: 24px;
    line-height: 35px;
  }
  .EventsDescription p {
    font-size: 16px;
  }

  .cardleaders {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
@media screen and (max-width: 820px) {
  #container {
    width: 500px;
  }
  .contactBanner {
    margin-right: 0px;
  }
  .card-container {
    margin-left: 0px;
    margin-bottom: 1em;
    width: 100%;
    align-content: center;
  }
  .about-image-section {
    display: none;
  }
  .outer-about-text-section {
    width: 100%;
  }
  /* .contactText {
    display: none;
  } */
  .contactText {
    height: 300px;
    margin-right: 20px;
  }
  .contactText p {
    font-size: 23px;
  }
  .Contactgrid-item {
    grid-column: span 2;
  }
  .locationtxt {
    display: none;
  }
  .contactForm {
    width: 100%;
  }
  .contactText {
    display: none;
  }
  .maps iframe {
    width: 100%;
  }
  .services .row {
    margin-top: 0px;
  }
  .event-section img {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  .cardleaders {
    display: flex;
    gap: 1rem;
  }
  #container {
    width: 250px;
  }
  .services .container h1 {
    top: 2%;
    left: 12%;
  }
  .about-image-section {
    display: none;
  }
  .about-text-section {
    padding-left: 20px;
  }
  .about-text-section h2 {
    font-size: 35px;
  }
  .contactText {
    display: none;
  }
  .outer-about-text-section {
    width: 100%;
  }
  .card-container {
    width: 100%;
    margin-bottom: 1em;
    margin-left: 0px;
    gap: 1em;
    align-content: center;
  }
  .contactBanner {
    margin-right: 0px;
  }
  .contactText {
    height: 300px;
    margin-right: 20px;
  }
  .contactText p {
    font-size: 23px;
  }
  .Contactgrid-item {
    grid-column: span 2;
  }
  #footer-company-image {
    margin-top: 10px;
    float: none;
  }
  .locationtxt {
    display: none;
  }
  #location1 {
    font-size: 14px;
  }
  .EventsDescription {
    padding: 30px;
    /* margin-top: 60px; */
  }
  .EventsDescription h6 {
    padding-top: 10px;
    margin-left: 20px;
    /* text-align: center; */
  }
  .EventsDescription h2 {
    font-size: 27px;
    line-height: 35px;
  }
  .EventsDescription p {
    /* font-size: 42px; */
    text-align: justify;
  }
  .Footer img {
    /* margin-left: 0px; */
    align-items: center;
  }
  .Footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .circle {
    height: 80px;
    width: 80px;
  }
  .Footer hr {
    color: white;
    width: 100%;
  }
  .next-line {
    display: block;
    /* margin-left: 30px; */
  }
  .card-text {
    margin-top: 10px;
    font-weight: 400;
    font-size: 15px;
  }
  .services .row {
    margin-top: 0px;
  }

  .event-section img {
    height: fit-content;
    width: 100%;
    object-fit: contain;
    align-items: center;
  }
  .leaders {
    padding: 30px 20px 40px 20px;
  }
}

@media (max-width: 321px) {
  .services .card {
    height: 19rem;
    width: 18.7rem;
  }
}

@media screen and (max-width: 291px) {
  /* .next-line {
    display: block;
  } */

  .services .card {
    height: 19rem;
    width: 17rem;
  }
  .Footer img {
    display: block;
    height: 85px;
    width: 200px;
    /* margin-left: 25px; */
  }
  .card-text {
    margin-top: 10px;
    font-weight: 400;
    font-size: 13px;
  }
  .footera {
    font-size: 13px;
  }

  .circle {
    height: 80px;
    width: 80px;
  }
  #location1 {
    font-size: 13px;
  }
  .services .row {
    margin-top: 0px;
  }
}
