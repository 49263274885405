.outer-swiper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.outer-section {
  max-width: 120rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 0.5rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiperContainer {
  height: fit-content;
  padding: 2rem 0;
  position: relative;
}

.swiperSlide {
  width: 40rem;
  height: 30rem;
  position: relative;
}

@media (max-width: 1180px) {
  .swiperContainer {
    height: fit-content;
  }
  .swiperSlide {
    width: 20rem !important;
    height: 20rem !important;
  }
  .swiperSlide img {
    width: 20rem !important;
    height: 15rem !important;
    border-radius: 2rem;
    object-fit: contain;
  }
}
@media (max-width: 500px) {
  .swiperContainer {
    height: fit-content !important;
  }
  .swiperSlide {
    width: 20rem !important;
    height: 20.5rem !important;
  }
  .swiperSlide img {
    width: 22rem !important;
    height: 15rem !important;
    border-radius: 2rem;
    object-fit: cover;
  }
}
.swiperSlide img {
  width: 42rem;
  height: 20rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiperSlide-shadow-left,
.swiperSlide-shadow-right {
  display: none;
}

.sliderControler {
  position: relative;
  bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderControler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .sliderControler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}
@media (max-width: 450px) {
  .sliderControler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .sliderControler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .sliderControler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.sliderControler .slider-arrow {
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.sliderControler .slider-arrow ion-icon {
  font-size: 1rem;
  color: #222224;
}

.sliderControler .slider-arrow::after {
  content: "";
}

.swiperPagination {
  position: relative;
  width: 6rem !important;
  left: 1.3rem !important;
  bottom: 1rem;
}

.swiperPagination .swiperPagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(11, 25, 58, 0.5));
}

.swiperPagination .swiperPagination-bullet-active {
  background: white;
}

.blur-slide {
  filter: blur(8px); /* Adjust the blur intensity as needed */
  opacity: 0.01;
}

.active-slide {
  filter: none;
}

.event__message--container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.1rem;
}

.event__message--container p {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
}

.events__message {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin: 0;
}

.events__message span {
  color: #ff6b00;
}
