.backgound-section {
  background-image: url(../../image.jpg);
  /* background-image: url(../../img/croppedlogo.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: fit-content;
}
.outer-section {
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.outer-section {
  max-width: 120rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 0.5rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 32rem;
  padding: 2rem 0;
  position: relative;
}

.up-swiper-slide {
  width: auto;
  height: 25rem;
  position: relative;
  /* top: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.up-swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 1rem !important;
  object-fit: contain;
  /* border: 2px solid black; */
  /* box-shadow: 5px 10px 8px #888888; */
}

.up-swiper-slide-shadow-left,
.up-swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

.slider-controler .slider-arrow {
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 1rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 6rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(11, 25, 58, 0.5));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: white;
}

.blur-slide {
  filter: blur(8px); /* Adjust the blur intensity as needed */
  opacity: 0.01;
}

.active-slide {
  filter: none;
}

/* @media (max-width: 1440px) {
  .swiper_container {
    margin-top: 2rem;
  }
} */

@media (max-width: 1100px) {
  .backgound-section {
    height: fit-content;
  }
  .swiper_container {
    margin-top: 1rem;
  }
  .swiper_container {
    height: 30rem;
    padding: 2rem 0;
    position: relative;
  }
  .up-swiper-slide {
    width: auto;
    height: 24rem;
  }
}

@media (max-width: 500px) {
  .backgound-section {
    height: fit-content;
  }
  .swiper_container {
    width: fit-content;
    height: 22rem;
  }

  .swiper_container {
    margin-top: 0rem;
  }
  .up-swiper-slide {
    height: 20.5rem !important;
  }
  .up-swiper-slide img {
    width: 23rem !important;
    height: auto !important;
    border-radius: 2rem;
    object-fit: cover;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
    display: none;
  }

  .up-swiper-slide img {
    /* width: 20rem !important;
    height: 15rem !important; */
    border-radius: 1rem;
    object-fit: contain;
    /* border: 2px solid black; */
    /* box-shadow: 5px 10px 8px #888888; */
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
    display: none;
  }
}

.events__banner {
  height: 90vh;
}

@media (width = 1024px) and (height = 600px) {
  .events__banner {
    height: 105vh !important;
  }
}

@media (width<1025px) {
  .events__banner {
    height: 70vh;
  }
}

@media (width<640px) {
  .events__banner {
    height: 55vh;
  }
  .events__banner--image {
    width: 350px;
  }
}
@media (width<350px) {
  .events__banner--image {
    width: 300px;
  }
}
