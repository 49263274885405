.TopSection {
  height: 30rem !important;
  width: 100%;
  background-image: url("../../img/GroupPhoto1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  text-align: center;
  color: white;
  padding-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textSection h1 {
  text-align: center;
  color: white;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.TopSection .textSection p {
  color: white;
  text-align: center;
}
.textSection {
  width: 70%;
}

@media screen and (max-width: 480px) {
  .textSection {
    width: 90%;
  }
}
