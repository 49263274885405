.outerDetails {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 80px 0px 50px 0px;
  /* height: 30rem; */
}

.textSection {
  width: 40%;
  padding-right: 20px;
}
.textSection h2 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
  color: #c42814;
}
.textSection p {
  /* font-family: "Poppins", sans-serif; */
  text-align: justify;
  font-size: medium;
}
.imageSection {
  /* position: relative; */
  /* height: inherit; */

  width: 43%;
}
.imageSection img {
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
}

/* .imageSection img {
  position: absolute;
  height: 200px;
  object-fit: cover;
  top: 25%;
  left: 20%;
  width: 350px;
  border-radius: 15px;
}
.rectangleUp {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 10%;
  left: 10%;
  background-color: #555;
  border-radius: 100px 100px 0px 0px;
  background-color: #c42814;
}
.rectangleDown {
  position: absolute;
  height: 150px;
  width: 150px;
  bottom: 15%;
  right: 6%;
  background-color: #555;
  border-radius: 0px 0px 100px 100px;
  background-color: #c42814;
} */
@media screen and (max-width: 820px) {
  .outerDetails {
    flex-direction: column;
  }
  .textSection {
    width: 80%;
  }
  p {
    padding: 0%;
    margin: 0%;
    padding-bottom: 20px;
  }
  .imageSection {
    width: 70%;
  }
}
@media screen and (max-width: 480px) {
  .outerDetails {
    flex-direction: column;
  }
  .textSection {
    width: 80%;
    padding: 0px;
  }
  p {
    padding: 0%;
    margin: 0%;
    padding-bottom: 20px;
  }
  .imageSection {
    width: 70%;
  }
}
