.TopSection {
  height: 30rem !important;
  width: 100%;
  background-image: url("../img/image2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  text-align: center;
  color: white;
  padding-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outerSection {
  background-image: url("../img/aboutbg1.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 1;
}
.textSection h2 {
  text-align: center;
  letter-spacing: 0.3rem;
  color: white;
}
.TopSection .textSection p {
  color: white;
  text-align: center;
  font-size: 18px;
}
.textSection {
  color: white;
  width: 70%;
}
.clientOuterSection {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clientsSlide {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.clientsSlide img {
  display: inline-block;
  height: 120px;
  width: 120px;
  object-fit: contain;
  margin: 50px 35px;
}

.webtunedLogo {
  width: 200px !important;
  object-fit: cover;
}
@media screen and (max-width: 480px) {
  .TopSection {
    padding-top: 10rem;
  }
  .textSection {
    width: 90%;
  }
  .clientsSlide {
    width: 100%;
  }
  .clientsSlide img {
    display: inline-block;
    margin: 30px 20px;
  }
}
