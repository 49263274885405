.Card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.hangers {
  margin: 0px 20px;
}
.hangers h4 {
  font-size: 23px;
  text-align: left;
}
.Card img {
  height: 250px;
  width: 350px;
  margin: 5px 10px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.Card span {
  background-color: #c42814;
}
.imagesection {
  position: relative;
  margin: 10px 0px;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 95%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 18px;
  padding: 18px;
  margin: 0px 10px 5px 10px;
  border-radius: 0px 0px 10px 10px;
  text-align: center;
}

.imagesection:hover .overlay {
  opacity: 1;
}

/*  */

.gallery-outer-section ::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.06);
  z-index: -1;
}
.gallery {
  width: 100%;
  margin: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  /* background-color: black; */
}

.image-card {
  padding: 5px;
  /* border: 1px solid whitesmoke; */
  border-radius: 5px;
}
.image {
  width: 100%;
  /* object-fit: cover; */
  border-radius: 5px;
}

.image:hover {
  filter: brightness(1.3);
}
.tags {
  text-align: center;
  padding: 50px 0px;
  /* background-color: rgb(43, 92, 226); */
}

.tag {
  outline: none;
  border: none;
  color: white;
  margin: 0px 10px;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
}
.galleryhr {
  color: antiquewhite;
  width: 100%;
  font-size: 20px;
}
.actively {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 7px;
}

/* model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  /* top: 70px;
  left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.7);
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  /* z-index: 999; */
}
.modelimage {
  /* height: 400px; */
  position: relative;
}
.model.open {
  top: 5%;
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  z-index: 999;
}

.model.img {
  width: 100%;
  /* max-width: 100%; */
  height: 200px;
  /* height: auto; */
  /* max-height: 100%; */
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  object-fit: cover;
}

.modelimage i {
  position: absolute;
  top: 10%;
  right: 7%;
  /* width: 200px;
  height: 200px; */
  padding: 5px;
  /* background-color: black; */
  color: antiquewhite;
  cursor: pointer;
  z-index: 1000;
}

/* color: rgb(255, 255, 255);
position: absolute;
top: 70px;
right: 90px; */

.submenu h6:hover {
  color: var(--primar-color);
}
.gallery-outer {
  display: flex;
}
@media (max-width: 991px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
  .image {
    width: 95%;
    /* object-fit: cover; */
    border-radius: 5px;
  }
  .modelimage i {
    position: absolute;
    top: 0;
    right: 5%;
    /* width: 200px;
    height: 200px; */
    padding: 5px;
    background-color: black;
    color: antiquewhite;
    cursor: pointer;
    z-index: 1000;
  }
}

/*  */
@media screen and (max-width: 991px) {
  .Card img {
    height: 200px;
    width: 300px;
    margin: 5px 10px;
    border-radius: 10px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  }
  .Card {
    margin-top: 3rem;
  }
}
