
.gallery-outer-section {
  position: relative;
  /* background-color: black; */
  background-image: url(../img/background4.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 1;
  padding-bottom: 2rem;
}
.gallery-outer-section ::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.06);
  z-index: -1;
}
.gallery {
  width: 100%;
  margin: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  /* background-color: black; */
}

.image-card {
  padding: 5px;
  /* border: 1px solid whitesmoke; */
  border-radius: 5px;
}
.image {
  width: 100%;
  /* object-fit: cover; */
  border-radius: 5px;
}

.image:hover {
  filter: brightness(1.3);
}
.tags {
  text-align: center;
  padding: 50px 0px;
  /* background-color: rgb(43, 92, 226); */
}

.tag {
  outline: none;
  border: none;
  color: white;
  margin: 0px 10px;
  background-color: transparent;
  cursor: pointer;
  font-size: 17px;
  line-height: 2em;
}
.galleryhr {
  color: antiquewhite;
  width: 100%;
  font-size: 20px;
}
.actively {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 7px;
}

/* model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  /* top: 70px;
  left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.7);
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  /* z-index: 999; */
}
.modelimage {
  /* height: 400px; */
  position: relative;
}
.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  z-index: 999;
}

.model.img {
  width: 100%;
  /* max-width: 100%; */
  /* height: auto; */
  /* max-height: 100%; */
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  object-fit: cover;
}

.modelimage i {
  position: absolute;
  top: 10%;
  right: 7%;
  /* width: 200px;
  height: 200px; */
  padding: 5px;
  /* background-color: black; */
  color: antiquewhite;
  cursor: pointer;
  z-index: 1000;
}

/* color: rgb(255, 255, 255);
position: absolute;
top: 70px;
right: 90px; */

.submenu h6:hover {
  color: var(--primar-color);
}
.gallery-outer {
  display: flex;
}
@media (max-width: 991px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
  .image {
    width: 95%;
    /* object-fit: cover; */
    border-radius: 5px;
  }
  .modelimage i {
    position: absolute;
    top: 0;
    right: 5%;
    /* width: 200px;
    height: 200px; */
    padding: 5px;
    /* background-color: black; */
    color: antiquewhite;
    cursor: pointer;
    z-index: 1000;
  }
  .tag {
    text-align: center;
    display: block;
    line-height: 1.4em;
    /* background-color: rgb(43, 92, 226); */
  }
}
