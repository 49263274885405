.TopSection {
  height: 30rem !important;
  width: 100%;
  background-image: url("../img/background6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  text-align: center;
  color: white;
  padding-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopSection h1 {
  text-align: center;
  color: white;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}
.TopSection .textSection p {
  color: white;
  text-align: center;
}
.textSection {
  width: 70%;
}

.workflowbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  gap: 10px;
  box-sizing: border-box;
  margin-top: 5rem;
}
.workflowbody a {
  text-decoration: none;
  color: white;
}
.workflowbody h2 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
  margin-top: 30px;
  color: #c42814;
}

.skewed {
  width: 85%;
  color: #f1f1f1;
  display: flex;
  background: #c42814;
  margin: 2em 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.text {
  padding: 1.5em;
  width: 35%;
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  flex-direction: column;
}

.image {
  width: 65%;
  height: 25em;
  background-color: white;
  color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

.image::before {
  content: "";
  position: absolute;
  left: -5.8em;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  width: 20%;
  height: 140%;
  background: #c42814;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.skewedLeft .image::before {
  transform: translateY(-50%) rotate(-15deg);
  left: initial;
  right: -6.1em;
}
.learnText {
  padding-left: 20%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
}
.buildtext {
  padding-right: 20%;
  padding-left: 10%;
}
.text p {
  text-align: justify;
}
.image h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1em;
  letter-spacing: 0.25rem;
  font-family: "Poppins", sans-serif;
}

.textButton {
  background-color: #c42814;
  border-radius: 10px;
  padding: 10px 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: #e9e7e2;
  border: 2px solid;
  width: fit-content;
}
.textButton:hover {
  /* background-color: var(--emaroon); */
  /* color: var(--ecream); */
  color: #c42814;
  background-color: #e9e7e2;
  border: 2px solid#c42814;
}
.image img {
  object-fit: cover;
  height: 40rem;
}

@media screen and (max-width: 820px) {
  .text h1 {
    /* display: none; */
    font-size: 20px;
  }
}
@media screen and (max-width: 750px) {
  .skewed {
    display: block;
    width: 20em;
  }
  .image,
  .text {
    width: 100%;
  }
  .image h5 {
    font-size: 20px;
  }
  .Workp {
    padding-bottom: 50px;
    font-size: 15px;
  }
  .learnText {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 30%;
  }
  .buildtext {
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 10%;
  }
  .skewed .image::before {
    width: 110%;
    height: 35%;
    top: -5em;
    left: 50%;
    transform: translateX(-50%) rotate(10deg);
  }
  .skewedLeft .image::before {
    transform: translateX(-50%) rotate(-10deg);
    top: initial;
    bottom: -5em;
  }
  .image img {
    object-fit: cover;
    height: 20em;
    overflow: hidden;
  }
  .image {
    height: 20em;
  }
}
@media screen and (max-width: 480px) {
  .textSection {
    width: 90%;
  }
}
@media screen and (max-width: 378px) {
  .image {
    object-fit: cover;
    height: 20em;
    overflow: hidden;
  }
  .image {
    height: 20em;
  }
}
