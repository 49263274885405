.TopSection {
  height: 30rem !important;
  width: 100%;
  background-image: url("../img/EventManagement1.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  color: white;
  padding-top: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .imageSection {
  height: 30rem;
  width: 100%;
} */
/* .imageSection img {
  height: inherit;
  width: 100%;
  object-fit: cover;
  object-position: 100% 5%;
  filter: brightness(50%);
  display: block;
} */

.textSection h1 {
  text-align: center;
  color: white;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}
.TopSection .textSection p {
  color: white;
  text-align: center;
}
.textSection {
  width: 70%;
}
.navigation {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  color: black;
}
.navigation ul {
  list-style-type: none;
}

.navigation ul li {
  position: relative;
  float: left;
  font-size: 20px;
  padding: 20px;
  display: block;
}

.navigation ul li:hover {
  position: relative;
  float: left;
  font-size: 20px;
  padding: 20px;
  display: block;
  color: red;
}

.navigation ul li ul {
  position: absolute;
  left: 0;
  top: 70px;
  width: 200px;
  background-color: white;
  display: none;
  z-index: 7;
}

.navigation ul li ul li {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.navigation ul li:focus > ul,
.navigation ul li:hover > ul {
  display: initial;
}
#menubar {
  display: none;
}

.rentals label {
  font-size: 20px;
  color: #333;
  cursor: pointer;
  display: none;
}
/* .gallery {
  margin-top: 8rem;
} */
.optionMenu hr {
  width: 100%;
  margin-top: 0px;
}

.tag {
  outline: none;
  border: none;
  color: #c42814;
  font-weight: bold;
  margin: 0px 10px;
  letter-spacing: 1px;
  background-color: transparent;
  cursor: pointer;
  border: none;
}
.actively {
  /* font-weight: bold; */
  text-decoration: underline;
  text-underline-offset: 7px;
}

@media screen and (max-width: 991px) {
  /* .navigation {
    background-color: green;
    width: 100%;
  } */
  .optionMenu {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    left: 0;
    height: 50px;
    bottom: 0px;
    background-color: white;
    z-index: 12;
    width: 100%;
    padding-top: 0px;
  }
  .optionMenu hr {
    display: none;
  }
  .optionMenu ul {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0px;
    gap: 4px;
    align-content: center;
    justify-content: center;
  }
  .navigation ul li {
    position: relative;
    /* float: left; */
    font-size: 16px;
    margin: 0px;
    padding: 0 4px !important;
    display: block;
  }
  .rentals label {
    display: initial;
  }
  .tag {
    outline: none;
    border: none;
    color: #c42814;
    font-weight: bold;
    margin: 0px;
    letter-spacing: 0.1px;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .navigation ul li {
    position: relative;
    float: left;
    font-size: 15px;
    display: block;
  }

  .navigation ul li:hover {
    position: relative;
    float: left;
    font-size: 15px;
    display: block;
    color: red;
  }

  .navigation ul li ul {
    position: absolute;
    left: 0;
    top: 70px;
    width: 200px;
    background-color: white;
    display: none;
    z-index: 7;
  }

  .navigation ul li ul li {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 480px) {
  .textSection {
    width: 90%;
  }
  .tag {
    font-size: 12px;
  }
}
@media screen and (max-width: 285px) {
  .tag {
    font-size: 10px;
  }
}
