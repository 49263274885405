.outerBackground {
  background-image: url(../img/aboutbg4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.TopSection {
  height: 30rem !important;
  width: 100%;
  background-image: url("../img/background8.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  text-align: center;
  color: white;
  padding-top: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .imageSection img {
  height: inherit;
  width: 100%;
  object-fit: cover;
  object-position: 100% 5%;
  filter: brightness(50%);
  display: block;
} */

.textSection h1 {
  text-align: center;
  color: white;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}
.TopSection .textSection p {
  color: white;
  text-align: center;
}
.textSection {
  width: 70%;
}
.outerSection {
  margin-top: 4em;
  padding-bottom: 4em;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 20px;
  color: white;
}
.griditem {
  flex-basis: 40%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 21rem;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  background-color: #eaeaea;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.divh5 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.griditem h5 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.griditem:hover .divh5 {
  position: absolute;
  transform: translateY(-80px);
  opacity: 0;
  visibility: hidden;
  width: 90%;
}

.description {
  position: absolute;
  top: 100%;
  /* width: 60%; */
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;
  box-sizing: border-box;
  transition: 0.8s;
  overflow: hidden;
  opacity: 0;
}
.griditem:hover .description {
  top: 0;
  opacity: 1;
  overflow: hidden;
  width: 90%;
}
.griditem p {
  font-size: 18px;
  line-height: 1.5em;
  letter-spacing: 0.08em;
  /* margin-top: 20px; */
}
/* .innerSection {
  flex-basis: 50%; 
  flex-grow: 0;
  flex-shrink: 0;
  height: 100px;
  background-color: #eaeaea;
  padding: 20px;
  box-sizing: border-box;
} */

@media screen and (max-width: 820px) {
  .griditem h5 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700;
    transition: all 0.5s ease-in-out;
  }
}
@media screen and (max-width: 551px) {
  .griditem {
    flex-basis: 80%;
  }
}
@media screen and (max-width: 480px) {
  /* .imageSection img {
    height: inherit;
    width: 100%;
    object-fit: cover;
    object-position: 50% 5%;
    filter: brightness(50%);
    display: block;
  } */

  .textSection {
    width: 90%;
  }
  .griditem h5 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700;
    transition: all 0.5s ease-in-out;
  }
}
